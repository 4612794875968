import { FC, useRef } from 'react';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { AppFooter } from './AppFooter';
import { AppHeader } from './AppHeader';
import { AppRoutes } from './AppRoutes';

const PageWrapper = styled.section`
  height: 100%;

  /* grid container settings */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'main'
    'footer';
`;
const HeaderWrapper = styled.section`
  grid-area: header;
  width: 100%;
  background: rgb(5, 15, 37);
`;
const ContentWrapper = styled.section`
  grid-area: main;
`;
const FooterWrapper = styled.section`
  grid-area: footer;
  width: 100%;
  background: rgb(5, 15, 37);
`;
export interface AppProps {
  sectionRefs: React.MutableRefObject<null | HTMLDivElement>[];
}
export const App: FC = () => {
  const sectionRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];

  return (
    <>
      <PageWrapper>
        <HeaderWrapper>
          <AppHeader sectionRefs={sectionRefs} />
        </HeaderWrapper>
        <ContentWrapper>{<AppRoutes sectionRefs={sectionRefs} />}</ContentWrapper>
        <FooterWrapper>
          <AppFooter sectionRefs={sectionRefs} />
        </FooterWrapper>
      </PageWrapper>
      <ToastContainer />
    </>
  );
};
