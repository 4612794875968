import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1440px;
  margin: auto;
`;
export const SectionTitle = styled.div`
  font-family: Roboto;
  font-size: 40px;
  color: #050f25;
  font-weight: bold;
  margin: auto;
  margin-bottom: 60px;
  letter-spacing: 1px;
`;
export const SectionBlock = styled.div`
  width: 100%;
  margin: auto;
  text-align: center;
`;
export const Spacer = styled.div`
  flex-grow: 1;
`;
export const SimpleText = styled.span`
  text-align: left;
  font-size: 16px;
  font-family: Roboto;
  color: #6d6d6d;
  letter-spacing: 1px;
  line-height: 30px;
`;
export const Card = styled.div<{ border: string }>`
  margin: auto;
  background-color: white;
  max-width: 800px;
  margin-bottom: 20px;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 2px 3px 10px rgba(52, 58, 64, 0.1);
  border: ${props => props.border};
`;
export const CardContent = styled.div<{ isMobile?: boolean }>`
  margin: auto;
  align-items: start;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  height: 100%;
  gap: 30px;
`;
