import LogoSvg from 'assets/logo2.svg';
import { Container, SimpleText, Spacer } from 'components/styled-components';
import { FC } from 'react';
import { FaRegCopyright } from 'react-icons/fa';
import { SiFacebook, SiGithub, SiInstagram, SiLinkedin, SiTwitter } from 'react-icons/si';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AppProps } from './App';

const Menu = styled.div`
  width: 100%;
`;
const MenuContainer = styled.div`
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 1440px;
  height: 100%;
`;
const LogoContainer = styled.div<{ isMobile: boolean }>`
  margin: auto;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  height: 100%;
  gap: 30px;
`;
const SocLinkWrapper = styled.div`
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
  height: 100%;
  gap: 15px;
`;
const LogoImg = styled.img`
  width: 200px;
  cursor: pointer;
`;
const SimpleTextModified = styled(SimpleText)`
  font-weight: 600;
  color: white;
`;
const IconLink = styled(Link)`
  color: white;
  font-size: 25px;
  :hover {
    color: #00bfff;
  }
`;
const Copyright = styled.div`
  margin: auto;
  padding: 20px;
  text-align: center;
  background-color: #171f31;
`;

export const AppFooter: FC<AppProps> = ({ sectionRefs }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });

  return (
    <>
      <Container>
        <Menu>
          <MenuContainer>
            <LogoContainer isMobile={isTabletOrMobile}>
              <Spacer />
              <LogoImg
                src={LogoSvg}
                alt="Evaldas Laureckas Software Development"
                onClick={() => {
                  sectionRefs[0].current?.scrollIntoView({
                    behavior: 'smooth'
                  });
                }}
              />
              <SocLinkWrapper>
                <SimpleTextModified>Follow Me:</SimpleTextModified>
                <IconLink to="https://www.facebook.com/evaldas.laureckas/" target="_blank">
                  <SiFacebook />
                </IconLink>
                <IconLink to="https://www.instagram.com/laureckas.evaldas/" target="_blank">
                  <SiInstagram />
                </IconLink>
                <IconLink to="https://github.com/piratuks/" target="_blank">
                  <SiGithub />
                </IconLink>
                <IconLink to="https://twitter.com/ELaureckas/" target="_blank">
                  <SiTwitter />
                </IconLink>
                <IconLink to="https://www.linkedin.com/in/evaldas123456/" target="_blank">
                  <SiLinkedin />
                </IconLink>
                <Spacer />
              </SocLinkWrapper>
              <Spacer />
            </LogoContainer>
          </MenuContainer>
        </Menu>
      </Container>
      <Copyright>
        <SimpleText>
          Copyright 2023 <FaRegCopyright /> and Handcrafted by me &quot;Evaldas Laureckas&quot;
        </SimpleText>
      </Copyright>
    </>
  );
};
