import LogoSvg from 'assets/logo1.svg';
import MenuHoverSvg from 'assets/menu-hover.svg';
import MenuSvg from 'assets/menu.svg';
import ProfilePicPng from 'assets/profile-pic.png';
import ScrollDownSvg from 'assets/scroll-down.svg';
import { Button } from 'components/Button';
import { IconButton } from 'components/IconButton';
import { Container, Spacer } from 'components/styled-components';
import { FC, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import useScrollSpy from 'react-use-scrollspy';
import styled from 'styled-components';
import { AppProps } from './App';

const Background = styled.div<{ isMobile: boolean }>`
  height: ${props => (props.isMobile ? '1100px' : '765px')};
  width: 100%;
  background: url('background-header.svg') center center / cover no-repeat;
`;
const Menu = styled.div<{ hasShadow: boolean }>`
  height: 80px;
  width: 100%;
  position: fixed;
  z-index: 1030;
  top: 0;
  left: 0px;
  background: url('background-menu1.png') center top / cover no-repeat;
  box-shadow: ${props => (props.hasShadow ? '0 6px 15px rgba(52, 58, 64, 0.3)' : 'none')};
`;
const MenuContainer = styled.div`
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 1440px;
  height: 100%;
  background: url('background-menu1.png') top center / cover no-repeat;
`;
const SubMenuContainer = styled.div`
  margin: auto;
  width: 100%;
  align-items: center;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
  height: 100%;
`;
const LogoImg = styled.img`
  width: 200px;
  cursor: pointer;
`;
const NavLinkWrapper = styled.div``;
const NavLink = styled.a<{ isActive: boolean }>`
  font-family: Roboto;
  color: ${props => (props.isActive ? '#daed1a' : 'white')};
  text-decoration: unset;
  padding-left: 20px;
  padding-right: 20px;
  letter-spacing: 1px;
  :hover {
    color: #00bfff;
    text-decoration: underline;
  }
  :active {
    text-decoration: unset;
  }
`;
const NavLinkWrapperResponsive = styled.div<{ show: boolean }>`
  background: url('background-menu2.png') top center / cover no-repeat;
  opacity: ${props => (props.show ? 1 : 0)};
  transform: translateY(${props => (props.show ? '0' : '-10px')});
  transition: opacity 0.3s ease, transform 0.3s ease;
  height: ${props => (props.show ? 'auto' : 0)};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
`;
const NavLinkResponsive = styled.a<{ isActive: boolean }>`
  font-family: Roboto;
  color: ${props => (props.isActive ? '#daed1a' : 'white')};
  text-decoration: unset;
  padding: 10px;
  display: block;
  width: 100%;
  letter-spacing: 1px;
  :hover {
    color: #00bfff;
    text-decoration: underline;
  }
  :active {
    text-decoration: unset;
  }
`;
const HeaderContainer = styled.div`
  margin: auto;
  align-items: center;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
  height: 100%;
`;
const ScrollDownImg = styled.img`
  width: 20px;
  margin-right: 30px;
`;
const ProfileImg = styled.img``;
const ProfileImgResponsive = styled.img`
  margin-top: 60px;
  width: 350px;
  margin-bottom: 30px;
`;
const Introduction = styled.div`
  margin: auto;
`;
const ParagraphIntro1 = styled.span`
  font-family: Roboto;
  font-size: 29px;
  color: white;
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
  letter-spacing: 1px;
`;
const ParagraphIntro2 = styled.span`
  font-family: Roboto;
  font-size: 64px;
  color: white;
  display: block;
  font-weight: bold;
  letter-spacing: 1px;
`;
const ParagraphIntro3 = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  margin-top: 10px;
`;
const ParagraphIntro4 = styled.div`
  display: block;
  margin-top: 30px;
  margin-bottom: 90px;
`;
const ParagraphIntro5 = styled.div`
  display: block;
`;
const HighlightWrapper = styled.div`
  display: flex;
`;
const HighlightBlock = styled.div``;
const Highlight = styled.span`
  font-family: Roboto;
  font-size: 32px;
  color: white;
  font-weight: bolder;
  display: block;
  margin-bottom: 10px;
  letter-spacing: 1px;
`;
const HighlightInfo = styled.span`
  font-family: Roboto;
  font-size: 15px;
  display: block;
  color: #cacaca;
  letter-spacing: 1px;
`;
const HighlightSeparator = styled.div`
  height: 50px;
  background-color: #1e3743;
  width: 4px;
  margin-left: 40px;
  margin-right: 40px;
`;
const ParagraphIntroLine = styled.div`
  height: 3px;
  background-color: white;
  width: 115px;
  display: inline-block;
  vertical-align: super;
  margin-left: 5px;
  margin-right: 5px;
`;
const ParagraphIntroLineText = styled.div`
  font-family: Roboto;
  font-size: 19px;
  color: white;
  display: inline-block;
  font-weight: 600;
  letter-spacing: 1px;
`;
export const AppHeader: FC<AppProps> = ({ sectionRefs }) => {
  const [showResponsiveMenu, setShowResponsiveMenu] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 850px)'
  });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 850px)' });
  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -270
  });
  const handleHireMe = () => {
    sectionRefs[4].current?.scrollIntoView({ behavior: 'smooth' });
  };
  const navActiveSection = useScrollSpy({
    sectionElementRefs: [sectionRefs[0]],
    offsetPx: 0,
    activeSectionDefault: -1
  });
  const handleMenuResponsive = () => {
    setShowResponsiveMenu(!showResponsiveMenu);
  };

  return (
    <Container>
      <Menu hasShadow={navActiveSection === 0}>
        <MenuContainer>
          <SubMenuContainer>
            <LogoImg
              src={LogoSvg}
              alt="Evaldas Laureckas Software Development"
              onClick={() => {
                sectionRefs[0].current?.scrollIntoView({
                  behavior: 'smooth'
                });
              }}
            />
            {isDesktopOrLaptop && (
              <>
                <NavLinkWrapper>
                  <NavLink
                    isActive={activeSection === 1}
                    href="#"
                    onClick={event => {
                      event.preventDefault();
                      sectionRefs[1].current?.scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    About Me
                  </NavLink>
                  <NavLink
                    isActive={activeSection === 2}
                    href="#"
                    onClick={event => {
                      event.preventDefault();
                      sectionRefs[2].current?.scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    Services
                  </NavLink>
                  <NavLink
                    isActive={activeSection === 3}
                    href="#"
                    onClick={event => {
                      event.preventDefault();
                      sectionRefs[3].current?.scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    Projects
                  </NavLink>
                  <NavLink
                    isActive={activeSection === 4}
                    href="#"
                    onClick={event => {
                      event.preventDefault();
                      sectionRefs[4].current?.scrollIntoView({ behavior: 'smooth' });
                    }}
                  >
                    Contact
                  </NavLink>
                </NavLinkWrapper>
                <Spacer />
                {/* <Button alt={'Download Resume'} label={'Download CV'} src={DownloadSvg} onClick={fileDownload} /> */}
              </>
            )}
            {isTabletOrMobile && (
              <>
                <IconButton alt={'Menu'} src={MenuSvg} onClick={handleMenuResponsive} srcHover={MenuHoverSvg} />
              </>
            )}
          </SubMenuContainer>

          {isTabletOrMobile && (
            <NavLinkWrapperResponsive show={showResponsiveMenu}>
              <NavLinkResponsive
                isActive={activeSection === 1}
                href="#"
                onClick={event => {
                  event.preventDefault();
                  sectionRefs[1].current?.scrollIntoView({ behavior: 'smooth' });
                  handleMenuResponsive();
                }}
              >
                About Me
              </NavLinkResponsive>
              <NavLinkResponsive
                isActive={activeSection === 2}
                href="#"
                onClick={event => {
                  event.preventDefault();
                  sectionRefs[2].current?.scrollIntoView({ behavior: 'smooth' });
                  handleMenuResponsive();
                }}
              >
                Services
              </NavLinkResponsive>
              <NavLinkResponsive
                isActive={activeSection === 3}
                href="#"
                onClick={event => {
                  event.preventDefault();
                  sectionRefs[3].current?.scrollIntoView({ behavior: 'smooth' });
                  handleMenuResponsive();
                }}
              >
                Projects
              </NavLinkResponsive>
              <NavLinkResponsive
                isActive={activeSection === 4}
                href="#"
                onClick={event => {
                  event.preventDefault();
                  sectionRefs[4].current?.scrollIntoView({ behavior: 'smooth' });
                  handleMenuResponsive();
                }}
              >
                Contact
              </NavLinkResponsive>
            </NavLinkWrapperResponsive>
          )}
        </MenuContainer>
      </Menu>
      <Background ref={sectionRefs[0]} isMobile={isTabletOrMobile}>
        <HeaderContainer>
          {isDesktopOrLaptop && <ScrollDownImg src={ScrollDownSvg} alt="Scroll Down" />}
          <Introduction>
            <ParagraphIntro1>Hello I&apos;m</ParagraphIntro1>
            <ParagraphIntro2>Evaldas</ParagraphIntro2> <ParagraphIntro2>Laureckas</ParagraphIntro2>
            <ParagraphIntro3>
              <ParagraphIntroLine />
              <ParagraphIntroLineText>Software Developer</ParagraphIntroLineText>
            </ParagraphIntro3>
            {isDesktopOrLaptop && (
              <ParagraphIntro4>
                <Button
                  label={'Hire Me'}
                  onClick={() => {
                    handleHireMe();
                  }}
                />
              </ParagraphIntro4>
            )}
            {isTabletOrMobile && <ProfileImgResponsive src={ProfilePicPng} alt="Profile Picture" />}
            <ParagraphIntro5>
              <HighlightWrapper>
                <HighlightBlock>
                  <Highlight>11+</Highlight>
                  <HighlightInfo>Year Of Experience</HighlightInfo>
                </HighlightBlock>
                <HighlightSeparator />
                <HighlightBlock>
                  <Highlight>9+</Highlight>
                  <HighlightInfo>Recommendations</HighlightInfo>
                </HighlightBlock>
              </HighlightWrapper>
            </ParagraphIntro5>
          </Introduction>
          {isDesktopOrLaptop && <Spacer />}
          {isDesktopOrLaptop && <ProfileImg src={ProfilePicPng} alt="Profile Picture" />}
        </HeaderContainer>
      </Background>
    </Container>
  );
};
