import AvatarSvg from 'assets/avatar.svg';
import { SectionBlock, SectionTitle, SimpleText, Spacer } from 'components/styled-components';
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

const AboutContainer = styled.div<{ isMobile: boolean }>`
  margin: auto;
  align-items: center;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  height: 100%;
`;
const AvatarImg = styled.img<{ isMobile: boolean }>`
  height: ${props => (props.isMobile ? '350px' : '500px')};
  margin-bottom: ${props => (props.isMobile ? '30px' : '0')};
`;
const List = styled.ul`
  padding-left: 30px;
  padding-right: 30px;
  list-style: disc;
`;
export const About: FC = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 900px)'
  });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });

  return (
    <>
      <SectionBlock>
        <SectionTitle>About</SectionTitle>
        <AboutContainer isMobile={isTabletOrMobile}>
          {isDesktopOrLaptop && <Spacer />}
          <AvatarImg src={AvatarSvg} alt="Profile Avatar" isMobile={isTabletOrMobile} />
          <SimpleText>
            <p>
              Master’s degree within marine engineering and bachelor’s degree from faculty of natural science,
              mathematics computer science department. I am an Innovative software engineer offering over eleven years
              of experience in the entire software development lifecycle – from concept through delivery of applications
              and customizable solutions. I have been developing in-house business system, small and large enterprise I
              can analyse code and engineer well-researched, cost-effective, and responsive solutions. I have ability to
              quickly acquire the knowledge you need for the task at hand.
            </p>
            <List>
              <li>Analyzing end user needs and then developing software to meet their needs</li>
              <li>Developing features across multiple applications</li>
              <li>Respond promptly to reports of bugs</li>
              <li>Service level reporting</li>
              <li>Clearly communicating technical concepts</li>
              <li>Maintaining proper software configuration management</li>
              <li>Ensuring proper source code documentation</li>
              <li>Continuous deployment</li>
              <li>Have experience in fully remote jobs/positions</li>
              <li>Experience in leading the team</li>
              <li>Experience in teams within Enterprise / Start-up setup</li>
            </List>
          </SimpleText>
          {isDesktopOrLaptop && <Spacer />}
        </AboutContainer>
      </SectionBlock>
    </>
  );
};
